import {
  Add,
  Clear,
  DeleteTwoTone,
  EditTwoTone,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

function AddCustomers() {
  const token = sessionStorage.getItem("Token");
  const userid = sessionStorage.getItem("userid");

  const [customers, setcustomers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [name, setName] = useState("");
  const [Dialogopen, setDialogOpen] = React.useState(false);
  const [Deleteid, setDeleteid] = useState("");
  const [deleteName, setDeletename] = useState("");
  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const getCustomers = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/qrinvoice/api/v1/user/getCustomers/${userid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data.Customers));
        setcustomers(response.data.Customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCustomers();
  }, []);
  const handleDialogOpen = (id, name) => {
    setDialogOpen(true);
    setDeleteid(id);
    setDeletename(name);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDeleteid("");
    setDeletename("");
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setEditMode(false);
    setEditIndex(null);
    setName("");
  };
  const handleAddItem = () => {
    if (editMode) {
      try {
        let data = new FormData();
        data.append("user_id", parseInt(userid));
        data.append("customer_name", name);
        const token = sessionStorage.getItem("Token");
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `https://simplesoft.co.in/qrinvoice/api/v1/user/updateCustomer/${editIndex}`,
          headers: {
            Authorization: `Bearer ${token} `,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            enqueueSnackbar("Name Updated Successfully", {
              variant: "success",
            });
            getCustomers();
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    } else {
      try {
        let data = new FormData();
        data.append("user_id", parseInt(userid));
        data.append("customer_name", name);
        const token = sessionStorage.getItem("Token");
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://simplesoft.co.in/qrinvoice/api/v1/user/addCustomer",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            getCustomers();
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    }
    handleCloseModal();
  };
  const filterItems = customers.filter((item) =>
    item?.name?.toUpperCase().includes(search?.toUpperCase())
  );
  const handleEditItem = (index, id) => {
    setEditMode(true);
    setEditIndex(id);
    setName(filterItems[index].name);
    setOpenModal(true);
  };
  const handleDeleteItem = () => {
    try {
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/qrinvoice/api/v1/user/deleteCustomer/${Deleteid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          getCustomers();
          handleDialogClose();
          enqueueSnackbar("Customer Deleted", {
            variant: "error",
          });
        })
        .catch((error) => {});
    } catch (error) {}
  };
  return (
    <div style={{ padding: "10px" }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Customers
      </Typography>
      <TextField
        sx={{
          "& fieldset": { borderRadius: 20 },
          position: "sticky",
          top: 89,
          bgcolor: "white",
          borderRadius: 20,
          zIndex: 5,
        }}
        fullWidth
        placeholder="Search here..."
        label="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {search && (
                <Clear
                  sx={{ cursor: "pointer" }}
                  onClick={() => setSearch("")}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2} sx={{ p: 1, mb: 7, mt: 1 }}>
        {filterItems.map((item, index) => (
          <Grid key={item.id} item xs={6} md={3} lg={3}>
            <Card
              sx={{
                maxWidth: 345,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // height: 300,
                borderRadius: 2,
                transform: "scale(1)",
                transition: " all ease-in 0.2s",
                backgroundSize: "cover",
                "&:hover": {
                  transform: "scale(1.01)",
                  filter: `drop-shadow(8px 8px 10px black)`,
                },
              }}
            >
              
              <Typography variant="h4" sx={{ mt: 2 }}>
                {item.name}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="primary"
                  sx={{
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                  onClick={() => handleEditItem(index, item.id)}
                >
                  <Tooltip title="Edit">
                    <EditTwoTone />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="error"
                  sx={{
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                  onClick={() => handleDialogOpen(item.id, item.name)}
                >
                  <Tooltip title="Delete">
                    <DeleteTwoTone />
                  </Tooltip>
                </IconButton>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleOpenModal}
        >
          <Add /> Add New Customer
        </Button>
      </div>
      <Dialog
        open={Dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h4"
            fontWeight={600}
          >{`Are You Sure Delete "${deleteName}" Customer?`}</Typography>
        </DialogTitle>

        <DialogActions>
          <Button color="error" onClick={() => handleDeleteItem()}>
            Delete
          </Button>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <TextField
            fullWidth
            label="Customer Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button sx={{ mt: 1 }} variant="contained" onClick={handleAddItem}>
              {editMode ? "Update" : "Add"}
            </Button>
            <Button
              sx={{ ml: 1, mt: 1 }}
              variant="contained"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AddCustomers;
