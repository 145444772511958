import React from "react";
import "./billtemplate2.css";
function BillTemplate2({ params }) {
  return (
    <div>
      <div class="container">
        <div class="header">
          <div></div>
          <div class="title">Invoice type 2</div>
          <div></div>
        </div>

        <div class="invoice-info">
          <div>
            <p>Invoice Date: {params.row.invoice_date}</p>
          </div>
          <div>
            <p>Invoice No.: {params.row.invoice_number}</p>
          </div>
          <div>
            <p>Place to Supply: Delhi</p>
          </div>
        </div>

        <div class="bill-info">
          <div>
            <p>
              Bill To:
              <br />
              Name: {params.row.customer_name}
              <br />
              Address: $Address
              <br />
              Contact No: +91 $Mobile_No
            </p>
          </div>
          <div>
            <p>
              Bill From:
              <br />
              Name: $name
              <br />
              Address: $Address
              <br />
              Contact No: +91 $Mobile_No
            </p>
          </div>
        </div>

        <table>
          <tr>
            <th>#</th>
            <th>Item Name</th>
            <th>Price/Unit</th>
            <th>QTY</th>
            <th>Amount</th>
          </tr>
          {JSON.parse(params.row?.data).map((item, index) => (
            <tr>
              <td> {index + 1}</td>
              <td> {item.name}</td>
              <td>
                {" "}
                <span>₹ </span>
                {item.price}
              </td>
              <td>{item.quantity}</td>
              <td>
                {" "}
                <span>₹ </span> {item.price * item.quantity}
              </td>
            </tr>
          ))}
        </table>

        <div class="totals">
          <div>
            <p>
              Sub Total: <span>₹ </span>
              {params.row?.total_amount}
            </p>
            {/* <p>GST: ₹$ReceivedBalance</p> */}
            <p>
              Total Due: <span>₹ </span>
              {params.row?.total_amount}
            </p>
          </div>
          <div class="signature">
            <p>Signature:</p>
            <img
              alt=""
              src="https://e7.pngegg.com/pngimages/895/900/png-clipart-electronic-signature-signature-miscellaneous-angle.png"
            />
          </div>
        </div>

        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <p>UPI ID: Random@ybl</p>
          <p>CUSTOM FIELD: Custom Field info</p>
          <p>CUSTOM FIELDS: random@123</p>
        </div>
      </div>
    </div>
  );
}

export default BillTemplate2;
