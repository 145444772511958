import React from "react";
import StateCard from "./Dashboardcomponents/StateCard";
import { Card,  styled } from "@mui/material";
import InvoiceList from "./Dashboardcomponents/InvoiceList";

function AdminDashboard() {
  const ContentBox = styled("div")(({ theme }) => ({
    margin: "30px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
  }));
  const StyledCard = styled(Card)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "24px !important",
    background: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: { padding: "16px !important" },
  }));
  const H4 = styled("h3")(({ theme }) => ({
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "16px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <div>
      <H4>States</H4>
      <StyledCard>
        <StateCard />
      </StyledCard>
      <H4>Invoices</H4>
      <ContentBox>
        <InvoiceList />
      </ContentBox>
    </div>
  );
}

export default AdminDashboard;
