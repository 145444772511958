import {
  Cancel,
  CheckCircle,
  Clear,
  Close,
  Info,
  Pending,
  Search,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
// import { useReactToPrint } from "react-to-print";
import { Print } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import html2pdf from "html2pdf.js";
import { ReactTyped } from "react-typed";
import Typewriter from "typewriter-effect";
import Billtemplate1 from "./Billtemplate1";
import BillTemplate2 from "./BillTemplate2";
import Billtemplate3 from "./Billtemplate3";
function InvoiceList() {
  // const componentref = useRef();
  // const handleprint = useReactToPrint({
  //   content: () => componentref.current,
  // });

  const ActionButton = ({ params }) => {
    const [open, setopen] = useState(false);
    const [type, setype] = React.useState(1);

    const handleTypeChange = (event) => {
      setype(event.target.value);
    };
    const handleopen = () => {
      setopen(true);
    };
    const handleclose = () => {
      setopen(false);
    };
    const contentref = useRef();
    const Generatepdf = () => {
      const element = contentref.current;
      const opt = {
        margin: 1,
        filename: `${params.row.invoice_number}`,
        image: { type: "png", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
      };
      html2pdf().from(element).set(opt).save();
    };
    return (
      <>
        <IconButton onClick={handleopen}>
          <Info />
        </IconButton>
        <Dialog open={open} onClose={handleclose} fullScreen>
          <div style={{ display: "flex", justifyContent: "space-between",alignItems:'center' }}>
            <DialogTitle id="alert-dialog-title">Details</DialogTitle>
            <div>
              <FormControl sx={{ width: 100,mt:1,mr:1 }}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Age"
                  onChange={handleTypeChange}
                >
                  <MenuItem value={1}>type 1</MenuItem>
                  <MenuItem value={2}>type 2</MenuItem>
                  <MenuItem value={3}>type 3</MenuItem>
                </Select>
              </FormControl>
              <Button
                sx={{ mt: 1 }}
                color="primary"
                onClick={Generatepdf}
                variant="contained"
              >
                <Print />
              </Button>
              <IconButton onClick={handleclose} color="error">
                <Close />
              </IconButton>
            </div>
          </div>

          <DialogContent>
            <div ref={contentref}>
              {/* <div
                style={{
                  minHeight: "auto",
                  width: "100%",
                  height: "auto",
                  backgroundColor: "rgba(246, 221, 178, 0.5)",
                  border: "solid 3px #000000 ",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      paddingRight: "20px",
                      paddingTop: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    Invoice
                  </div>
                </div>

                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: 20,
                  }}
                >
                  <div style={{ marginLeft: 0 }}>
                    <p>
                      Invoice Date
                      <br />
                      Date : 19/92/73
                      <br />
                    </p>
                  </div>

                  <p>
                    Invoice No.
                    <br />
                    {params.row.invoice_number}
                  </p>
                  <p>
                    Place to Supply <br />
                    Delhi
                  </p>
                </div>
                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: 20,
                    marginTop: "5px",
                  }}
                >
                  <p>
                    Bill To :<br />
                    Name : {params.row.customer_name}
                    <br />
                    Address : Address12344
                    <br />
                    Contact No : +91 23456789
                  </p>
                  <p>
                    Bill From :<br />
                    Name : name <br />
                    Address : Adress2
                    <br />
                    Contact No : +91 1234567890
                  </p>
                </div>
                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: 20,
                    marginTop: "5px",
                  }}
                >
                  <div>
                    <p>
                      Supply Type Code :<br />
                      EOPXS
                    </p>
                    <p>
                      IBN: <br />
                      43242 3432423 12312ADADV
                    </p>
                  </div>
                  <p>
                    Document Type Code
                    <br />
                    EOPXS
                  </p>

                  <div>
                    <p>QR Code </p>
                    <img
                      style={{
                        height: "90px",
                        width: "90px",
                        marginRight: "15px",
                      }}
                      alt=""
                      src="https://w7.pngwing.com/pngs/619/184/png-transparent-qr-code-barcode-scanners-scanner-q-text-rectangle-logo-thumbnail.png"
                    />
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tr
                        style={{
                          backgroundColor: "rgba(255, 0, 62, 0.8)",
                          color: "white",
                        }}
                      >
                        <th style={{ height: "30px" }}>S No.</th>
                        <th style={{ height: "30px" }}>Item Name</th>
                        <th style={{ height: "30px" }}>Price/Unit</th>
                        <th style={{ height: "30px" }}>QTY</th>
                        <th style={{ height: "30px" }}>Amount</th>
                      </tr>
                      {JSON.parse(params.row?.data).map((item, index) => (
                        <tr
                          style={{
                            backgroundColor: "rgba(246, 221, 178, 0.8)",
                          }}
                        >
                          <td style={{ textAlign: "center", height: "30px" }}>
                            {index + 1}
                          </td>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            {item.name}
                          </td>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            <span>₹ </span>
                            {item.price}
                          </td>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            {item.quantity}
                          </td>

                          <td style={{ textAlign: "center", height: "30px" }}>
                            <span>₹ </span> {item.price * item.quantity}
                          </td>
                        </tr>
                      ))}
                    </table>
                    <div
                      style={{
                        width: "100%",
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ width: "40%" }}></div>
                      <table style={{ width: "50%", alignSelf: "flex-end" }}>
                        <tr>
                          <th style={{ textAlign: "start" }}>Sub Total: </th>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            <span>₹ </span>
                            {params.row?.total_amount}
                          </td>
                        </tr>
                        

                        <tr style={{ borderBottom: "solid" }}>
                          <th style={{ textAlign: "start" }}>Total Due: </th>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            <span>₹ </span>
                            {params.row?.total_amount}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      padding: 20,
                    }}
                  >
                    <div style={{ marginLeft: 0 }}>
                      <p>
                        UPI ID
                        <br />
                        Random@ybl
                      </p>
                      <p>
                        CUSTOM FIELD
                        <br />
                        Custom Field info
                      </p>
                      <p>
                        CUSTOM FIELDS
                        <br />
                        random@123
                      </p>
                    </div>
                    <div style={{ textAlign: "end" }}>
                      <p>Signature </p>
                      <img
                        style={{
                          height: "90px",
                          width: "90px",
                          marginRight: "15px",
                        }}
                        alt=""
                        src="https://e7.pngegg.com/pngimages/895/900/png-clipart-electronic-signature-signature-miscellaneous-angle.png"
                      />
                      <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <p>
                          CUSTOM FIELDS
                          <br />
                          CSCDSCDCDSCDCSDc
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {type == 1 && <Billtemplate1 params={params} />}
              {type == 2 && <BillTemplate2 params={params} />}
              {type == 3 && <Billtemplate3 params={params} />}
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  const PrintButton = ({ params }) => {
    const contentref1 = useRef();
    const Generatepdf = () => {
      const element = contentref1.current;
      const opt = {
        margin: 1,
        filename: `${params.row.invoice_number}`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
      };
      html2pdf().from(element).set(opt).save();
    };

    return (
      <>
        <IconButton onClick={Generatepdf}>
          <Print />
        </IconButton>

        <div ref={contentref1}>
          <div
            style={{
              minHeight: "auto",
              width: "100%",
              height: "auto",
              backgroundColor: "rgba(246, 221, 178, 0.5)",
              border: "solid 3px #000000 ",
            }}
          >
            <div
              style={{
                height: "auto",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  paddingRight: "20px",
                  paddingTop: "20px",
                }}
              >
                Invoice
              </div>
            </div>

            <div
              style={{
                height: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: 20,
              }}
            >
              <div style={{ marginLeft: 0 }}>
                <p>
                  Invoice Date
                  <br />
                  Date : 19/92/73
                  <br />
                </p>
              </div>

              <p>
                Invoice No.
                <br />
                {params.row.invoice_number}
              </p>
              <p>
                Place to Supply <br />
                Delhi
              </p>
            </div>
            <div
              style={{
                height: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: 20,
                marginTop: "5px",
              }}
            >
              <p>
                Bill To :<br />
                Name : {params.row.customer_name}
                <br />
                Address : Address12344
                <br />
                Contact No : +91 23456789
              </p>
              <p>
                Bill From :<br />
                Name : name <br />
                Address : Adress2
                <br />
                Contact No : +91 1234567890
              </p>
            </div>
            <div
              style={{
                height: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: 20,
                marginTop: "4px",
              }}
            >
              <div>
                <p>
                  Supply Type Code :<br />
                  EOPXS
                </p>
                <p>
                  IBN: <br />
                  43242 3432423 12312ADADV
                </p>
              </div>
              <p>
                Document Type Code
                <br />
                EOPXS
              </p>

              <div>
                <p>QR Code </p>
                <img
                  style={{
                    height: "90px",
                    width: "90px",
                    marginRight: "15px",
                  }}
                  alt=""
                  // src={image}

                  src="https://w7.pngwing.com/pngs/619/184/png-transparent-qr-code-barcode-scanners-scanner-q-text-rectangle-logo-thumbnail.png"
                />
              </div>
            </div>

            <div>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <tr
                    style={{
                      backgroundColor: "rgba(255, 0, 62, 0.8)",
                      color: "white",
                    }}
                  >
                    <th style={{ height: "30px" }}>S No.</th>
                    <th style={{ height: "30px" }}>Item Name</th>
                    <th style={{ height: "30px" }}>Price/Unit</th>
                    <th style={{ height: "30px" }}>QTY</th>
                    {/* <th style={{height: "30px"}}>Discount</th> */}
                    {/* <th style={{height: "30px"}}>GST</th> */}
                    <th style={{ height: "30px" }}>Amount</th>
                  </tr>
                  {JSON.parse(params.row?.data).map((item, index) => (
                    <tr style={{ backgroundColor: "rgba(246, 221, 178, 0.8)" }}>
                      <td style={{ textAlign: "center", height: "30px" }}>
                        {index + 1}
                      </td>
                      <td style={{ textAlign: "center", height: "30px" }}>
                        {item.name}
                      </td>
                      <td style={{ textAlign: "center", height: "30px" }}>
                        <span>₹ </span>
                        {item.price}
                      </td>
                      <td style={{ textAlign: "center", height: "30px" }}>
                        {item.quantity}
                      </td>
                      {/* <td style={{ textAlign: "center", height: "30px" }}>
                          ₹0
                        </td> */}
                      {/* <td style={{ textAlign: "center", height: "30px" }}>
                          ₹0
                        </td> */}
                      <td style={{ textAlign: "center", height: "30px" }}>
                        <span>₹ </span> {item.price * item.quantity}
                      </td>
                    </tr>
                  ))}
                </table>
                <div
                  style={{
                    width: "100%",
                    alignSelf: "flex-end",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ width: "40%" }}></div>
                  <table style={{ width: "50%", alignSelf: "flex-end" }}>
                    <tr>
                      <th style={{ textAlign: "start" }}>Sub Total: </th>
                      <td style={{ textAlign: "center", height: "30px" }}>
                        <span>₹ </span>
                        {params.row?.total_amount}
                      </td>
                    </tr>
                    {/* <tr style={{ borderBottom: "solid" }}>
                          <th style={{ textAlign: "start" }}>GST </th>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            ReceivedBalance
                          </td>
                        </tr> */}

                    <tr style={{ borderBottom: "solid" }}>
                      <th style={{ textAlign: "start" }}>Total Due: </th>
                      <td style={{ textAlign: "center", height: "30px" }}>
                        <span>₹ </span>
                        {params.row?.total_amount}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div
                style={{
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  padding: 20,
                }}
              >
                <div style={{ marginLeft: 0 }}>
                  <p>
                    UPI ID
                    <br />
                    Random@ybl
                  </p>
                  <p>
                    CUSTOM FIELD
                    <br />
                    Custom Field info
                  </p>
                  <p>
                    CUSTOM FIELDS
                    <br />
                    random@123
                  </p>
                </div>
                <div style={{ textAlign: "end" }}>
                  <p>Signature </p>
                  <img
                    style={{
                      height: "90px",
                      width: "90px",
                      marginRight: "15px",
                    }}
                    alt=""
                    src="https://e7.pngegg.com/pngimages/895/900/png-clipart-electronic-signature-signature-miscellaneous-angle.png"
                  />
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <p>
                      CUSTOM FIELDS
                      <br />
                      CSCDSCDCDSCDCSDc
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const userid = sessionStorage.getItem("userid");
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);

  const column = [
    { field: "invoice_number", headerName: "InvoiceNumber", width: 170 },
    { field: "invoice_date", headerName: "InvoiceDate", width: 150 },
    { field: "total_amount", headerName: "TotalAmount", width: 145 },
    { field: "customer_name", headerName: "CustomerName", width: 170 },
    {
      field: "payment_status",
      headerName: "Status",
      width: 110,
      renderCell: (params) =>
        params.value === "Paid" ? (
          <Tooltip title="Paid" disableInteractive>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckCircle style={{ color: "green" }} />
              {params.value}
            </div>
          </Tooltip>
        ) : params.value === "Pending" ? (
          <Tooltip title="pending" disableInteractive>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Pending style={{ color: "yellow" }} />
              {params.value}
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="Unpaid" disableInteractive>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Cancel style={{ color: "red" }} />
              {params.value}
            </div>
          </Tooltip>
        ),
    },
    {
      field: "action",
      headerName: "Info",
      width: 80,
      renderCell: (params) => <ActionButton params={params} />,
    },
    {
      field: "actions",
      headerName: "Print",
      width: 80,
      renderCell: (params) => <PrintButton params={params} />,
    },
  ];
  const getinvoices = () => {
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/qrinvoice/api/v1/user/getInvoices/${userid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setData(response.data.invoices);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("something went wrong", { variant: "error" });
      });
  };

  useEffect(() => {
    getinvoices();
  }, []);
  const [inputvalue, setInputvalue] = useState("");
  const inputHandler = (e) => {
    setInputvalue(e.target.value);
  };
  const filterdata = data.filter((item) => {
    const searchlower = inputvalue.toLowerCase();
    return (
      item.customer_name.toLowerCase().includes(searchlower) ||
      item.total_amount.toString().includes(searchlower) ||
      item.invoice_date.includes(searchlower) ||
      item.invoice_number.toString().includes(searchlower)
    );
  });

  return (
    <div>
      <Typewriter
        options={{
          strings: [
            "Search By Customer Name",
            "Search By Amount",
            "Search By Date",
            "Search by Invoice Number",
          ],
          autoStart: true,
          loop: true,
        }}
      />
      <TextField
        type="text"
        variant="outlined"
        placeholder={"Search..."}
        sx={{
          backgroundColor: "alternate.dark",
          mb: 2,
          [`& fieldset`]: {
            borderRadius: 30,
          },
        }}
        fullWidth
        value={inputvalue}
        onChange={inputHandler}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {inputvalue && (
                <Clear
                  sx={{ cursor: "pointer" }}
                  onClick={() => setInputvalue("")}
                />
              )}
            </InputAdornment>
          ),
        }}
      />

      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filterdata}
          columns={column}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          slots={{
            toolbar: GridToolbar,
          }}
        />{" "}
      </div>
    </div>
  );
}

export default InvoiceList;
