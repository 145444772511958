import React from "react";
import {  useRoutes } from "react-router";
import Adminroutes from "./AdminRoutes";
import "../index.css"
function AdminMain() {
  
  const content = useRoutes(Adminroutes);
  
  const isadmin = sessionStorage.getItem("user");
  if (isadmin)
     return<> <div>{content}</div></>
  else {
    return <h1>you are unauthorized</h1>;
  }
}

export default AdminMain;
