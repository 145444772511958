import React from "react";
import SidebarLayout from "./layouts/SidebarLayout";
// import BaseLayout from "./layouts/BaseLayout";
import AdminDashboard from "./AdminComponents/AdminDashboard";
import AddCustomers from "./AdminComponents/AddCustomers";
import AddItems from "./AdminComponents/AddItems";
import QrReaderList from "./AdminComponents/Dashboardcomponents/QrReaderList";

const Adminroutes = [
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "/admin/dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "/admin/addcustomers",
        element: <AddCustomers/>,
      },
      {
        path: "/admin/additems",
        element: <AddItems/>,
      },
      {
        path: "/admin/addinvoice",
        element: <QrReaderList/>,
      },
      {
        path: "/",
        element: <AdminDashboard/>,
      },

      // {
      //   path: "/admin/user_profile",
      //   element: <AddItems/>,
      // },
      {
        path: "*",
        element: <h1>not found404</h1>,
      },
    ],
  },
  // {
  //   path: "dashboards",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="tasks" replace />,
  //     },
  //     {
  //       path: "crypto",
  //       element: <Crypto />,
  //     },
  //     {
  //       path: "messenger",
  //       element: <Messenger />,
  //     },
  //   ],
  // },
  // {
  //   path: "management",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="transactions" replace />,
  //     },
  //     {
  //       path: "transactions",
  //       element: <Transactions />,
  //     },
  //     {
  //       path: "profile",
  //       children: [
  //         {
  //           path: "",
  //           element: <Navigate to="details" replace />,
  //         },
  //         {
  //           path: "details",
  //           element: <UserProfile />,
  //         },
  //         {
  //           path: "settings",
  //           element: <UserSettings />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: "/components",
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Navigate to="buttons" replace />,
  //     },
  //     {
  //       path: "buttons",
  //       element: <Buttons />,
  //     },
  //     {
  //       path: "modals",
  //       element: <Modals />,
  //     },
  //     {
  //       path: "accordions",
  //       element: <Accordions />,
  //     },
  //     {
  //       path: "tabs",
  //       element: <Tabs />,
  //     },
  //     {
  //       path: "badges",
  //       element: <Badges />,
  //     },
  //     {
  //       path: "tooltips",
  //       element: <Tooltips />,
  //     },
  //     {
  //       path: "avatars",
  //       element: <Avatars />,
  //     },
  //     {
  //       path: "cards",
  //       element: <Cards />,
  //     },
  //     {
  //       path: "forms",
  //       element: <Forms />,
  //     },
  //   ],
  // },
];

export default Adminroutes;
