import {
  Add,
  Clear,
  DeleteTwoTone,
  EditTwoTone,
  Print,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import ReactToPrint, { useReactToPrint } from "react-to-print";

function AddItems() {
  const token = sessionStorage.getItem("Token");
  const userid = sessionStorage.getItem("userid");
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [items, setitems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [name, setName] = useState("");
  const [price, setprice] = useState(0);
  const [Dialogopen, setDialogOpen] = React.useState(false);
  const [Deleteid, setDeleteid] = useState("");
  const [deleteName, setDeletename] = useState("");
  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const getitems = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/qrinvoice/api/v1/user/getItems/${userid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data.Items));
        setitems(response.data.Items);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getitems();
  }, []);
  const handleDialogOpen = (id, name) => {
    setDialogOpen(true);
    setDeleteid(id);
    setDeletename(name);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDeleteid("");
    setDeletename("");
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setEditMode(false);
    setEditIndex(null);
    setprice(0);
    setName("");
  };
  const handleAddItem = () => {
    if (editMode) {
      try {
        let data = new FormData();
        data.append("user_id", parseInt(userid));
        data.append("name", name);
        data.append("price", price);
        const token = sessionStorage.getItem("Token");
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `https://simplesoft.co.in/qrinvoice/api/v1/user/updateItem/${editIndex}`,
          headers: {
            Authorization: `Bearer ${token} `,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(response.data);
            enqueueSnackbar("Item Updated Successfully", {
              variant: "success",
            });
            getitems();
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    } else {
      try {
        let data = new FormData();
        data.append("user_id", parseInt(userid));
        data.append("name", name);
        data.append("price", price);
        const token = sessionStorage.getItem("Token");
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://simplesoft.co.in/qrinvoice/api/v1/user/addItems",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(response.data);
            getitems();
            enqueueSnackbar("Item Added Successfully", {
              variant: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {}
    }
    handleCloseModal();
  };
  const filterItems = items.filter((item) =>
    item?.name?.toUpperCase().includes(search?.toUpperCase())
  );
  const handleEditItem = (index, id) => {
    setEditMode(true);
    setEditIndex(id);
    setName(filterItems[index].name);
    setOpenModal(true);
    setprice(filterItems[index].price);
  };
  const handleDeleteItem = () => {
    try {
      const token = sessionStorage.getItem("Token");
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://simplesoft.co.in/qrinvoice/api/v1/user/deleteItem/${Deleteid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          getitems();
          handleDialogClose();
          enqueueSnackbar("Item Deleted", {
            variant: "error",
          });
        })
        .catch((error) => {});
    } catch (error) {}
  };
  const componentref1 = useRef([]);
  const componentref = useRef();
  const handleprint = useReactToPrint({
    content: () => componentref.current,
  });
  return (
    <div style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          position: "fixed",
          top:83,
          right: 50,
          zIndex: 6,
        }}
      >
        <Button color="primary" size="small" onClick={handleprint} variant="contained">
          <Print />
        </Button>
      </div>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Items
      </Typography>
      <TextField
        sx={{
          "& fieldset": { borderRadius: 20 },
          position: "sticky",
          top: 89,
          bgcolor: "white",
          borderRadius: 20,
          zIndex: 5,
        }}
        fullWidth
        placeholder="Search here..."
        label="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {search && (
                <Clear
                  sx={{ cursor: "pointer" }}
                  onClick={() => setSearch("")}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2} sx={{ p: 1, mb: 7, mt: 1 }} ref={componentref}>
        {filterItems.map((item, index) => (
          <Grid key={item.id} item xs={6} md={3} lg={3}>
            <Card
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: smallscreen ? 310 : 410,
                borderRadius: 2,
                transform: "scale(1)",
                transition: " all ease-in 0.2s",
                backgroundSize: "cover",
                "&:hover": {
                  transform: "scale(1.01)",
                  filter: `drop-shadow(8px 8px 10px black)`,
                },
              }}
            >
              <ReactToPrint
                trigger={() => (
                  <IconButton color="primary">
                    <Print />
                  </IconButton>
                )}
                content={() => componentref1.current[index]}
              />
              <div ref={(el) => (componentref1.current[index] = el)}>
                <QRCode
                  value={`{"id":"${item.id}","name":"${item.name}","price":"${item.price}"}`}
                  qrStyle={"dots"}
                  logoOpacity={0.5}
                  CornerRadii={[40, 50, 20]}
                  size={smallscreen ? 150 : 230}
                  ecLevel={"H"}
                  logoPaddingStyle={"circle"}
                  eyeRadius={[
                    { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                    { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                    { outer: [10, 10, 10, 10], inner: [10, 10, 10, 10] },
                  ]}
                />{" "}
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Name {item.name} &nbsp;
                </Typography>
              </div>
              <Typography variant="h4" sx={{ mt: 2 }}>
                Price: {item.price}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="primary"
                  sx={{
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                  onClick={() => handleEditItem(index, item.id)}
                >
                  <Tooltip title="Edit">
                    <EditTwoTone />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="error"
                  sx={{
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "all 0.5s",
                    },
                  }}
                  onClick={() => handleDialogOpen(item.id, item.name)}
                >
                  <Tooltip title="Delete">
                    <DeleteTwoTone />
                  </Tooltip>
                </IconButton>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          variant="contained"
          sx={{ width: "80%", p: 1, position: "fixed", bottom: 10 }}
          onClick={handleOpenModal}
        >
          <Add /> Add New Item
        </Button>
      </div>
      <Dialog
        open={Dialogopen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h4"
            fontWeight={600}
          >{`Are You Sure Delete "${deleteName}" Customer?`}</Typography>
        </DialogTitle>

        <DialogActions>
          <Button color="error" onClick={() => handleDeleteItem()}>
            Delete
          </Button>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Item Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Item Price"
                value={price}
                onChange={(e) => setprice(e.target.value)}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button sx={{ mt: 1 }} variant="contained" onClick={handleAddItem}>
              {editMode ? "Update" : "Add"}
            </Button>
            <Button
              sx={{ ml: 1, mt: 1 }}
              variant="contained"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AddItems;
