import React from 'react'

function Billtemplate1({params}) {
  return (
    <div>
         {/* <div ref={contentref}> */}
              <div
                style={{
                  minHeight: "auto",
                  width: "100%",
                  height: "auto",
                  backgroundColor: "rgba(246, 221, 178, 0.5)",
                  border: "solid 3px #000000 ",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      paddingRight: "20px",
                      paddingTop: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    Invoice type 1
                  </div>
                </div>

                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: 20,
                  }}
                >
                  <div style={{ marginLeft: 0 }}>
                    <p>
                      Invoice Date
                      <br />
                      Date : {params.row.invoice_date}
                      <br />
                    </p>
                  </div>

                  <p>
                    Invoice No.
                    <br />
                    {params.row.invoice_number}
                  </p>
                  <p>
                    Place to Supply <br />
                    Delhi
                  </p>
                </div>
                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: 20,
                    marginTop: "5px",
                  }}
                >
                  <p>
                    Bill To :<br />
                    Name : {params.row.customer_name}
                    <br />
                    Address : Address12344
                    <br />
                    Contact No : +91 23456789
                  </p>
                  <p>
                    Bill From :<br />
                    Name : name <br />
                    Address : Adress2
                    <br />
                    Contact No : +91 1234567890
                  </p>
                </div>
                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: 20,
                    marginTop: "5px",
                  }}
                >
                  <div>
                    <p>
                      Supply Type Code :<br />
                      EOPXS
                    </p>
                    <p>
                      IBN: <br />
                      43242 3432423 12312ADADV
                    </p>
                  </div>
                  <p>
                    Document Type Code
                    <br />
                    EOPXS
                  </p>

                  <div>
                    <p>QR Code </p>
                    <img
                      style={{
                        height: "90px",
                        width: "90px",
                        marginRight: "15px",
                      }}
                      alt=""
                      src="https://w7.pngwing.com/pngs/619/184/png-transparent-qr-code-barcode-scanners-scanner-q-text-rectangle-logo-thumbnail.png"
                    />
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tr
                        style={{
                          backgroundColor: "rgba(255, 0, 62, 0.8)",
                          color: "white",
                        }}
                      >
                        <th style={{ height: "30px" }}>S No.</th>
                        <th style={{ height: "30px" }}>Item Name</th>
                        <th style={{ height: "30px" }}>Price/Unit</th>
                        <th style={{ height: "30px" }}>QTY</th>
                        <th style={{ height: "30px" }}>Amount</th>
                      </tr>
                      {JSON.parse(params.row?.data).map((item, index) => (
                        <tr
                          style={{
                            backgroundColor: "rgba(246, 221, 178, 0.8)",
                          }}
                        >
                          <td style={{ textAlign: "center", height: "30px" }}>
                            {index + 1}
                          </td>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            {item.name}
                          </td>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            <span>₹ </span>
                            {item.price}
                          </td>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            {item.quantity}
                          </td>

                          <td style={{ textAlign: "center", height: "30px" }}>
                            <span>₹ </span> {item.price * item.quantity}
                          </td>
                        </tr>
                      ))}
                    </table>
                    <div
                      style={{
                        width: "100%",
                        alignSelf: "flex-end",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ width: "40%" }}></div>
                      <table style={{ width: "50%", alignSelf: "flex-end" }}>
                        <tr>
                          <th style={{ textAlign: "start" }}>Sub Total: </th>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            <span>₹ </span>
                            {params.row?.total_amount}
                          </td>
                        </tr>
                        {/* <tr style={{ borderBottom: "solid" }}>
                          <th style={{ textAlign: "start" }}>GST </th>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            ReceivedBalance
                          </td>
                        </tr> */}

                        <tr style={{ borderBottom: "solid" }}>
                          <th style={{ textAlign: "start" }}>Total Due: </th>
                          <td style={{ textAlign: "center", height: "30px" }}>
                            <span>₹ </span>
                            {params.row?.total_amount}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      padding: 20,
                    }}
                  >
                    <div style={{ marginLeft: 0 }}>
                      <p>
                        UPI ID
                        <br />
                        Random@ybl
                      </p>
                      <p>
                        CUSTOM FIELD
                        <br />
                        Custom Field info
                      </p>
                      <p>
                        CUSTOM FIELDS
                        <br />
                        random@123
                      </p>
                    </div>
                    <div style={{ textAlign: "end" }}>
                      <p>Signature </p>
                      <img
                        style={{
                          height: "90px",
                          width: "90px",
                          marginRight: "15px",
                        }}
                        alt=""
                        src="https://e7.pngegg.com/pngimages/895/900/png-clipart-electronic-signature-signature-miscellaneous-angle.png"
                      />
                      <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <p>
                          CUSTOM FIELDS
                          <br />
                          CSCDSCDCDSCDCSDc
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
    </div>
  )
}

export default Billtemplate1