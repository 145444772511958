import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Add,
  Clear,
  Close,
  FlashlightOff,
  FlashlightOn,
  FlipCameraAndroid,
  Remove,
} from "@mui/icons-material";
import { Scanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import { useSnackbar } from "notistack";
function QrReaderList() {
  const token = sessionStorage.getItem("Token");
  const userid = sessionStorage.getItem("userid");
  const initialid = () => {
    const savedid = localStorage.getItem("currentid");
    return savedid ? parseInt(savedid, 10) : 2000;
  };
  const [currentid, setcurrentid] = useState(initialid);

  useEffect(() => {
    const storedcart = JSON.parse(localStorage.getItem("Bills")) || [];
    setScan(storedcart);
  }, []);
  useEffect(() => {
    localStorage.setItem("currentid", currentid);
  }, [currentid]);
  const [scan, setScan] = useState([]);
  const [price1, setPrice1] = useState(0);
  const [customerid, setcustomerid] = useState([]);
  const [invoiceid, setinvoiceid] = useState("");
  const getCustomers = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/qrinvoice/api/v1/user/getCustomers/${userid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data.Customers));
        setcustomerid(response.data.Customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getinvoiceno = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://simplesoft.co.in/qrinvoice/api/v1/user/getInvoiceNumber/${userid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setinvoiceid(response.data.invoice);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCustomers();
    getinvoiceno();
  }, []);
  const handlescan = (data) => {
    handleClickOpen();
    if (data) {
      const product = JSON.parse(data);
      setScan((prev) => {
        const existingproduct = prev.findIndex((p) => p.id === product.id);
        if (existingproduct !== -1) {
          const updated = [...prev];
          updated[existingproduct].quantity += 1;
          enqueueSnackbar("Item Quantity Increased", { variant: "info" });
          return updated;
        } else {
          enqueueSnackbar("Item added Successfully", { variant: "success" });
          product.quantity = 1;
          return [...prev, product];
        }
      });
    }
  };
  const handleRemove = (product) => {
    const arr = scan.filter((item) => item.id !== product.id);
    setScan(arr);
    enqueueSnackbar("Item Removed ", { variant: "error" });
  };
  const handleMinus = (product_id) => {
    setScan((cart) =>
      cart.map((item) =>
        product_id === item.id
          ? { ...item, quantity: item.quantity - (item.quantity > 1 ? 1 : 0) }
          : item
      )
    );
  };
  const handlePlus = (product_id) => {
    setScan((cart) =>
      cart.map((item) =>
        product_id === item.id
          ? { ...item, quantity: item.quantity + (item.quantity >= 99 ? 0 : 1) }
          : item
      )
    );
  };
  const handlequantitychange = (productid, newquantity) => {
    if (newquantity === "") {
      const updatedcart = scan.map((item) =>
        item.id === productid ? { ...item, quantity: 1 } : item
      );
      setScan(updatedcart);
    } else if (newquantity <= 0) {
      const updatedcart = scan.map((item) =>
        item.id === productid ? { ...item, quantity: 1 } : item
      );
      setScan(updatedcart);
    } else if (newquantity > 99) {
      const updatedcart = scan.map((item) =>
        item.id === productid ? { ...item, quantity: 99 } : item
      );
      setScan(updatedcart);
    } else if (
      newquantity === "" ||
      (Number(newquantity) <= 99 && Number(newquantity) >= 1)
    ) {
      const updatedcart = scan.map((item) =>
        item.id === productid ? { ...item, quantity: newquantity } : item
      );
      setScan(updatedcart);
    }
    // const updatedcart = scan.map((item) =>
    //   item.id === productid ? { ...item, quantity: newquantity } : item
    // );
    // setScan(updatedcart);
  };
  const handlePrice = () => {
    let sum = 0;
    scan.map((item) => {
      let total = parseInt(item.price * item.quantity);
      sum += total;
      setPrice1(sum);
      return sum;
    });
  };
  useEffect(() => {
    handlePrice();
  }, [scan]);
  useEffect(() => {
    localStorage.setItem("Bills", JSON.stringify(scan));
  }, [scan]);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let time;
    if (open) {
      time = setTimeout(() => {
        handleClose();
      }, 3000);
    }
    return () => {
      clearTimeout(time);
    };
  }, [open]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [customer, setcustomer] = useState({});
  const [selectcustomerid, setselectcustomerid] = useState(0);
  const [tempname, settempname] = useState("");
  useEffect(() => {
    setselectcustomerid(customer.id);
    settempname(customer.name);
  }, [customer]);
  const handleChange = (event) => {
    setcustomer(event.target.value);
  };
  const [status, setstatus] = useState("Paid");

  const handlestatusChange = (event) => {
    setstatus(event.target.value);
  };
  const { enqueueSnackbar } = useSnackbar();
  const Generate = () => {
    let data = new FormData();
    data.append("user_id", parseInt(userid));
    data.append("invoice_number", invoiceid);
    data.append("invoice_date", new Date().toISOString().split("T")[0]);
    data.append("total_amount", price1);
    data.append("payment_status", status); 
    data.append("customer_name", tempname);
    data.append("customer_id", !customer.id ? 0 : selectcustomerid);
    data.append("data", JSON.stringify(scan));
    for (var pair of data.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    const token = sessionStorage.getItem("Token");

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://simplesoft.co.in/qrinvoice/api/v1/user/addInvoice",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        enqueueSnackbar(response.data.message, { variant: "success" });
        setScan([])
        getCustomers();
        getinvoiceno()
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("somthing went wrong", { variant: "error" });
      });
  };
  const [name, setName] = useState("");
  const [price, setprice] = useState("");
  const [quantity, setquantity] = useState("");
  const [Manuallyopen, setManuallyopen] = useState(false);

  const dialogmanuallyopen = () => {
    setManuallyopen(true);
  };

  const dialogmanuallyclose = () => {
    setManuallyopen(false);
    setName("");
    setprice("");
    setquantity("");
  };
  const addproductmanually = () => {
    const newitem = {
      id: currentid,
      name,
      quantity: parseInt(quantity),
      price,
    };
    enqueueSnackbar("Item Added Successfully", { variant: "success" });
    setScan([...scan, newitem]);
    dialogmanuallyclose();
    setcurrentid(currentid + 1);
  };
  const quantitymanual = (e) => {
    const inputvalue = e.target.value;
    if (
      inputvalue === "" ||
      (Number(inputvalue) <= 99 && Number(inputvalue) >= 1)
    ) {
      setquantity(inputvalue);
    }
    // heree..////
    // if (inputvalue === "") {
    //   setquantity(1);
    // } else if (inputvalue <= 0) {
    //   setquantity(1);
    // } else if (inputvalue > 99) {
    //   setquantity(99);
    // } else if (
    //   inputvalue === "" ||
    //   (Number(inputvalue) <= 99 && Number(inputvalue) >= 1)
    // ) {
    //   setquantity(inputvalue);
    // }
  };
  const pricemanual = (e) => {
    const inputvalue = e.target.value;
    if (
      inputvalue === "" ||
      (Number(inputvalue) > 0 && Number(inputvalue) >= 1)
    ) {
      setprice(inputvalue);
    }
  };
  const totalquantity = () => {
    let total = 0;
    scan.forEach((item) => {
      total += parseInt(item.quantity);
    });
    return total;
  };
  const handlefocus = (e) => {
    e.target.select();
  };
  const clear = () => {
    setcustomer({});
    settempname("");
  };
  const [torch, settorch] = useState(false);
  const handletorch = () => {
    settorch(!torch);
  };
  const [facingmode, setfacingmode] = useState("environment");
  const switchcamera = () => {
    setfacingmode(facingmode === "environment" ? "user" : "environment");
  };
  return (
    <>
      <div style={{ padding: "20px" }}>
        <Grid container spacing={1} justifyContent={"center"}>
          <Grid item xs={12} lg={6} sx={{ p: 1 }}>
            <Box
              sx={{
                height: smallscreen ? 400 : "100%",
              }}
            >
              {smallscreen && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton onClick={handletorch}>
                    {torch ? <FlashlightOff /> : <FlashlightOn />}
                  </IconButton>
                  <IconButton onClick={switchcamera}>
                    <FlipCameraAndroid />
                  </IconButton>
                </div>
              )}

              <Scanner
                paused={open}
                onScan={(result) => handlescan(result[0].rawValue)}
                allowMultiple={true}
                facingMode={facingmode}
                torch={torch}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ p: 1 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "5px",
                gap: 10,
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Customer Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={customer}
                  label="Customer Name"
                  onChange={handleChange}
                >
                  {customerid?.map((item, index) => (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton color="error" onClick={clear}>
                <Clear />
              </IconButton>
              <TextField
                disabled={selectcustomerid}
                fullWidth
                label="Customer Name"
                placeholder="customer name"
                value={tempname}
                onChange={(e) => settempname(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={handlestatusChange}
                >
                  <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                  <MenuItem value={"Paid"}>Paid</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                size="small"
                onClick={dialogmanuallyopen}
              >
                Add Manually
              </Button>
            </div>

            <List
              sx={{
                mb: 8,
                mt: { xs: 1, lg: 0 },
                mr: 1,
                maxHeight: smallscreen ? 300 : 460,
                // width:smallscreen?360 :"100%",
                overflow: "auto",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography align="right" variant="h4">
                  {/* Quantity:{totalquantity} */}
                  Invoice No: {invoiceid}
                </Typography>
                <Typography align="right" variant="h4">
                  {/* Quantity:{totalquantity} */}
                  Quantity: {totalquantity()}
                </Typography>
              </div>
              {scan?.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{ m: 1, bgcolor: "background.paper" }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography>Name: {item.name}</Typography>
                      </>
                    }
                    secondary={
                      <>
                        <Typography sx={{ mb: 1.5 }}>
                          Price: {item.price} ₹
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "stretch",
                            alignItems: "center",
                          }}
                        >
                          <IconButton onClick={() => handleMinus(item.id)}>
                            <Remove />
                          </IconButton>
                          <TextField
                            type="number"
                            sx={{ width: 40 }}
                            value={item.quantity}
                            variant="standard"
                            onFocus={handlefocus}
                            onChange={(e) =>
                              handlequantitychange(
                                item.id,
                                parseInt(e.target.value)
                              )
                            }
                          />
                          <IconButton onClick={() => handlePlus(item.id)}>
                            <Add />
                          </IconButton>
                          <Typography fontWeight={700} variant="body2">
                            Total: {item.price * item.quantity} ₹
                          </Typography>
                        </div>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="error"
                      onClick={() => handleRemove(item)}
                    >
                      <Close />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                onClick={Generate}
                className="target-1"
                variant="contained"
                sx={{ width: "40%", p: 1, position: "fixed", bottom: 10 }}
              >
                <Add />
                Generate Bill {price1}Rs
              </Button>
            </div>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography variant="h3">New Item Added</Typography>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Cancel</Button> */}
            <Button onClick={handleClose} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Manuallyopen}
          onClose={dialogmanuallyclose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Add Item Manually"}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} lg={4}>
                <TextField
                  value={name}
                  label="Item Name"
                  placeholder="Item Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  type="number"
                  value={price}
                  label="Item Price"
                  placeholder="Item Price"
                  onChange={pricemanual}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  type="number"
                  value={quantity}
                  label="Item Quantity"
                  placeholder="Item Quantity"
                  onChange={quantitymanual}
                  onFocus={handlefocus}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={dialogmanuallyclose}>Cancel</Button>
            <Button
              disabled={!price || !name || !quantity}
              onClick={addproductmanually}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default QrReaderList;
